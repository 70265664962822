import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "_helpers";
import dateUtils from "_helpers/date.utils";
import PageInnerHeader from "views/common/PageInnerHeader";
import { commonUtils } from "_helpers";
import { projectConstants } from "_constants";
import { accessUtils } from "_helpers";
import ReportPageTable from "./components/ReportPageTable";
import ItemNotFoundSection from "./components/ItemsNotFoundSection";
import { projectActions } from "_actions";
import CreateReportConfigSidebar from "./components/modal/CreateReportConfigSidebar";

let reportOptions = [
  { name: "dataDump", label: "Data Dump", viewEnabled: true },
  { name: "default", label: "Default"},
  { name: "format3", label: "Format 3" },
  { name: "employeeUtilization", label: "Employee Utilization", viewEnabled: true },
  { name: "myUtilization", label: "My Utilization", viewEnabled: true },
  { name: "myTimesheetData", label: "My Timesheet Data", viewEnabled: true },
  { name: "myTimesheetDataDump", label: "My Data Dump", viewEnabled: true },
  { name: "project15Percentage", label: "15% Report ", viewEnabled: true },
  { name: "estimateVsActualByDeliverablesByTaskDate", label: "Est vs Actual by Deliverables Date", viewEnabled: true },
  { name: "estimateVsActualByDeliverablesByActivityDate", label: "Est vs Actual by Activities Date", viewEnabled: true },
  { name: "siteActivity", label: "Site Activity", viewEnabled: true },
  { name: "projectDetailsBySkills", label: "Project Details By Skills" },
  { name: "weekWiseEmployeeUtilizationMonthly", label: "Weekwise Employee Utilization Monthly", viewEnabled: true },
  { name: "weekWiseEmployeeUtilizationMonthlyEndToEnd", label: "Weekwise Employee Utilization Monthly ENE", viewEnabled: true },
  { name: "weeklyPOEstimation", label: "Weekly PO Estimation", viewEnabled: true },
  { name: "weeklyProjectHours", label: "Weekly Project Hours", viewEnabled: true },
  { name: "utilizationByEmployeeBySkills", label: "Utilization By Skills", viewEnabled: true },
  { name: "utilizationByEmployeeByProjectCategory", label: "Utilization By Skills and Project Category", viewEnabled: true },
  { name: "resourceAllocation", label: "Resource Allocation Report", viewEnabled: true },
  { name: "projectCompletion", label: "Project Completion Report", viewEnabled: true }]

class ReportsPage extends Component {
  constructor() {
    super();
    this.state = {
      permission: accessUtils.getReportAccessControl(),
    }
  }
  componentDidMount() {
    const filterdFeportOptions = this.state.permission && reportOptions.filter(report => this.state.permission[report.name + "ReportEnabled"] === true) || [];
    if (filterdFeportOptions.length > 0) {
      this.setState({
        filterOptions: {
          dateRangeValue: dateUtils.getDefaultDateRange(),
          REPORT: { options: filterdFeportOptions, selected: filterdFeportOptions[0].name },
          filterText: null
        }
      })
    }
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
  }

  handleDateRangeChange = (daterange) => {
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        dateRangeValue: daterange
      }
    }), () => {
     this.handleFilterChange(this.state.filterOptions)
    })
  }

  handleFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: projectConstants.CHANGE_FILTER_REPORTS, filterOptions });
      });
  }

  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.handleFilterChange(this.state.filterOptions);
      }
    );
  }

  handleUserTeamsSelection = (userTeams) => {
    this.setState({ userTeams: userTeams },
      () => {
        store.dispatch({ type: projectConstants.INITIALIZE_REPORTS_USER_TEAMS, userTeams });
      });
  }

  handleViewReportData = () => {
    let selectedUserTeams = []
    let currentUserId = commonUtils.getCurrentUserId();
    this.props.userTeams && this.props.userTeams.map(userTeam => userTeam.isChecked && selectedUserTeams.push(userTeam.id));
    const startDate = this.state.filterOptions.dateRangeValue.start.format(dateUtils.SERVER_DATE_FORMAT);
    const endDate = this.state.filterOptions.dateRangeValue.end.endOf('day').format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(this.props.getViewReportData({ reportType: this.state.filterOptions.REPORT.selected, userTeamIds: selectedUserTeams, currentUserId: currentUserId, startDate: startDate, endDate: endDate }, this.state.filterOptions))
  }

  onCreateItemClick = (e) => {
    e.preventDefault();
    this.setState({ editMode: false });
    let defaultTeam = commonUtils.getCurrentUserTeamId();
    store.dispatch(this.props.initializeCreateReportConfig(defaultTeam));
  }
  onCloseCreateItem = (e) => {
    e.preventDefault();
    store.dispatch({ type: projectConstants.CLOSE_REPORTCONFIG_WINDOW });
  }

  render() {
    return (

      <div className="reports">
        {this.state.filterOptions && this.state.filterOptions.REPORT && this.state.filterOptions.REPORT.options && this.state.filterOptions.REPORT.options.length > 0 &&
          <PageInnerHeader
            userTeams={commonUtils.getUserTeams(this.props.userTeams)}
            handleUserTeamsSelection={this.handleUserTeamsSelection}
            //showGridDefault={this.state.showGrid}
            searchViewEnabled={false}
            showCreateMenu={this.state.permission.reportConfigurationEnabled}
            showGridListMenu={false}
            showDateActionButtons={true}
            showFilter={true}
            showUserTeamMenu={true}
            isLoading={this.props.isLoading}
            filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
            handleFilterChange={this.handleFilterChange}
            onFilterTextChange={this.onFilterTextChange}
            showCreateMenuText="Report Email Configuration"
            handleView={this.handleViewReportData}
            createItemHandler={this.onCreateItemClick}
            //gridClickHandler={this.onGridClick}
            //listClickHandler={this.onListClick}
            showDateRangeSelector={true}
            handleDateRangeChange={this.handleDateRangeChange}
            permission={this.state.permission}
          >
          </PageInnerHeader>
        }
        {((this.props.items && this.props.items.length > 0) || this.props.isLoading) &&
          <div className="section-body mt-1">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="tab-content">

                    <ReportPageTable
                      items={this.props.items}
                      metaInfo={this.props.metaInfo}
                      isLoading={this.props.isLoading}
                      handleDeleteProject={this.handleDeleteProject}
                      handleEditProject={this.handleEditProject}
                      handleArchiveProject={this.handleArchiveProject}
                      handleRemoveProject={this.handleRemoveProject}
                      handleFavoriteProject={this.handleFavoriteProject}
                      dateValueRange={this.state.filterOptions && this.state.filterOptions.dateRangeValue}
                      permission={this.state.permission} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <ItemNotFoundSection items={this.props.items} isLoading={this.props.isLoading} imgurl="reports-small-min.png" text="No Report Data found ?" description="Try Changing date range or Report Type..." />
        <CreateReportConfigSidebar
          show={this.props.showCreateModel}
          closeActionHandler={this.onCloseCreateItem}
          editMode={this.state.editMode}
          selectedReportConfigInfo={this.props.selectedReportConfigInfo}
          handleSubmit={this.handleSubmit}
          reportOptions={reportOptions}
          userTeams={commonUtils.getUserTeams(this.props.userTeams)}
          userTeamId={commonUtils.getCurrentUserTeamId()}
          scheduleType={"REPORT"}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.authentication,
  isLoading: state.reports.loading,
  userTeams: commonUtils.getUserTeams(state.reports.userTeams),
  metaInfo: state.reports.metaInfo,
  items: state.reports.items,
  filterOptions: state.reports.filterOptions,
  showCreateModel: state.reports.showCreateModel
});
const mapDispatchToProps = dispatch => ({
  getViewReportData: projectActions.getViewReportData,
  initializeCreateReportConfig: projectActions.initializeCreateReportConfig
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
