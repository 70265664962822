import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const scheduleService = {
    createSchedule,
    getSchedules,
    enableSchedule,
    removeSchedule

};
async function createSchedule(request, scheduleType) {
    return await axiosUtils.post(urlConfig.SCHEDULE_CREATE + (scheduleType === 'ITEM_RECURRING' ? '/item' : '/report'),request);
}

async function getSchedules(request) {
    if(request.scheduleType === 'REPORT') {
        return await axiosUtils.get(urlConfig.SCHEDULE_LIST+`/${request.scheduleType}?userTeamIds=${request.userTeamIds}`);
    }
    return await axiosUtils.get(urlConfig.SCHEDULE_LIST+`/${request.scheduleType}/${request.itemId}?userTeamId=${request.userTeamId}&itemId=${request.itemId}&type=${request.type}&scheduleType=${request.scheduleType}`);
}

async function enableSchedule(request) {
    return await axiosUtils.put(urlConfig.SCHEDULE_PATH+`/${request.itemId}/${request.isEnabled}`);
}

async function removeSchedule(request) {
    return await axiosUtils.remove(urlConfig.SCHEDULE_REMOVE+`/${request.itemId}`);
}
