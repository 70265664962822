import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import ServiceLineEstimate from "views/common/fragments/formfields/ServiceLineEstimate";
import { purchaseOrderActions } from "_actions";
import { store } from "_helpers";
import { commonUtils } from "_helpers";
import { validationUtils } from '_helpers'
import dateUtils from "_helpers/date.utils";
import workItemUtils from "_helpers/workitem.utils";
import UserTeamSelection from "../fragments/UserTeamSelection";
import SidebarFooter from "./SidebarFooter";

class CreatePurchaseOrderSidebar extends Component {
  componentDidMount() {

  }
  render() {
    return (
      <div id="createpurchaseOrdersidebar" className={`right_sidebar create_purchaseOrder_right_sidebar  ${this.props.show ? "open" : ""}`}>
        <div className="justify-content-between flex-column d-flex h-100vh">
          <div className="card-header py-0 border-bottom">
            <h3 className="card-title"> {this.props.editMode ? "Update" : this.props.duplicateMode ? "Duplicate" : "Create"} Purchase Order</h3>
            <div className="card-options">
              <button onClick={this.props.closeActionHandler} className="link p-2 create-purchaseOrder-close-link float-right"><i className="fa fa-close" /></button>
            </div>
          </div>
          {this.props.show && ((this.props.editMode && this.props.selectedPurchaseOrderInfo) || (this.props.duplicateMode && this.props.selectedPurchaseOrderInfo) || (!this.props.editMode && !this.props.duplicateMode)) &&

            <CreatePurchaseOrderSidebarSection
              handleSubmit={this.props.handleSubmit}
              editMode={this.props.editMode}
              duplicateMode={this.props.duplicateMode}
              selectedPurchaseOrderInfo={this.props.selectedPurchaseOrderInfo}
              currentUser={this.props.currentUser}
              fieldConfig={this.props.fieldConfig}
              createConfig={this.props.createConfig}
              allPurchaseOrderTeamAccounts={this.props.allPurchaseOrderTeamAccounts}
              allEmployees={this.props.allEmployees}
              employeeAppConfig={this.props.employeeAppConfig}
              allRoles={this.props.allRoles}
              allUserTeams={this.props.allUserTeams}
              isProcessing={this.props.creating || this.props.updating}
              initializeCreatePurchaseOrder={this.props.initializeCreatePurchaseOrder}
              permission={this.props.permission}

            />
          }

        </div>
      </div>
    );
  }
}

class CreatePurchaseOrderSidebarSection extends Component {

  constructor() {
    super();
    this.state = {
      formErrors: {},
      hasFormError: false
    }
  }

  componentDidMount() {
    this.initializeRequest();
  }
  onResetFormError = () => {
    this.setState({
      hasFormError: false
    })
  }

  initializeRequest = () => {
    let userTeamId = commonUtils.getCurrentUserTeamId();
    let purchaseOrderRequest = this.props.editMode ? this.props.selectedPurchaseOrderInfo : this.props.duplicateMode ? {
      ...this.props.selectedPurchaseOrderInfo,
      id: null,
      createdBy: commonUtils.getCurrentUserId(),
      modifiedBy: null,
    } :
      {
        userTeamId: userTeamId,
      }
    this.setState({
      purchaseOrderRequest: purchaseOrderRequest
    });
  }


  onFormError = (fieldName, hasError) => {
    this.setState(prevState => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError
      }
    }))
  }


  onChange = (fieldName, value) => {
    this.onResetFormError()
    if (fieldName === 'userTeamId') {
      store.dispatch(this.props.initializeCreatePurchaseOrder(value));
    }
    this.setState(prevState => ({
      purchaseOrderRequest: {
        ...prevState.purchaseOrderRequest,
        [fieldName]: value
      }
    }), () => {
      if (fieldName === 'estStartTime' || fieldName === 'estCompletionTime') {
        if (this.state.purchaseOrderRequest.estStartTime && this.state.purchaseOrderRequest.estCompletionTime) {
          let startDateMoment = dateUtils.convertDateStringToMoment(this.state.purchaseOrderRequest.estStartTime);
          let endDateMoment = dateUtils.convertDateStringToMoment(this.state.purchaseOrderRequest.estCompletionTime);
          if (dateUtils.compareTwoMoments(startDateMoment.startOf('day'), endDateMoment.endOf('day')) === -1) {
            this.setState(prevState => ({
              purchaseOrderRequest: {
                ...prevState.purchaseOrderRequest,
                [fieldName === 'estStartTime' ? 'estCompletionTime' : fieldName]: undefined,
              }
            }))
          }
        }
      }
    })
  }


  handleSubmit = (isCreateAnotherEnabled) => {
    let isValid = validationUtils.isValidRequest(this.props.fieldConfig, this.state.purchaseOrderRequest, this.state.formErrors);
    //console.log("Validating form  request, isValid - " + isValid);
    //console.log(this.state.purchaseOrderRequest);
    //console.log(this.state.formErrors)
    if (isValid) {
      const defaultNotificationEmails = commonUtils.getAppConfigValue(this.props.createConfig, "defaultPurchaseOrderNotificationEmails")
      const defaultPurchaseOrderNotificationEmailsForFinanceTeam = commonUtils.getAppConfigValue(this.props.createConfig, "defaultPurchaseOrderNotificationEmailsForFinanceTeam")
      let purchaseOrderRequestToBeSaved = {
        ...this.state.purchaseOrderRequest,
        watchers: [...(this.state.purchaseOrderRequest.watchers || (defaultNotificationEmails ? defaultNotificationEmails : []))],
        financeTeamWatchers: [...(defaultPurchaseOrderNotificationEmailsForFinanceTeam ? defaultPurchaseOrderNotificationEmailsForFinanceTeam : [])]
      }
      this.props.handleSubmit(purchaseOrderRequestToBeSaved, this.props.editMode, isCreateAnotherEnabled, !this.props.editMode ? this.initializeRequest : null);
    } else {
      this.setState({
        hasFormError: true
      })
    }
  }

  render() {
    return (
      <>
        {this.state.purchaseOrderRequest &&
          <>
            <div className="card-body pl-3 pr-3 overflow-auto">
              <div className="row">
                <div className="card pb-0 mb-0">
                  <div className="card-header pt-0 pb-1 d-none">
                    <span className="text-color-gray-aaa"><i className="fa fa-info-circle pr-1"></i>Basic details</span>
                    <div className="card-options">
                      <button className="link card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></button>
                    </div>
                  </div>
                  <div className="col-12 row">
                    <div className="col-12 col-lg-6">
                      <div className="row">
                        <div className="col-6">
                          <CustomInput inputtype='text'
                            label="Proposal Number"
                            name="proposalNumber"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Proposal Number..."
                            datatype='alpnumspace' />
                        </div>
                        <div className="col-6">
                          <CustomInput inputtype='text'
                            label="Client Project Id"
                            name="clientProjectId"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Client Project Id..."
                            datatype='alpnumspace' />
                        </div>
                      </div>
                      <div className="col-12 px-0">
                        <CustomInput inputtype='text'
                          label="Purchase Order Name"
                          name="name"
                          fieldConfig={this.props.fieldConfig}
                          mappingObject={this.state.purchaseOrderRequest}
                          onChange={this.onChange}
                          onFormError={this.onFormError}
                          placeholder="Purchase Order Name..."
                          datatype='alpnumspl' />
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            type='dateselect'
                            label="Estimated Start Date"
                            name="estStartTime"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            time={false}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type='dateselect'
                            label="Estimated Completion Date"
                            name="estCompletionTime"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            time={false}
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>

                      <div className="row">

                        <div className="col-3">
                          <CustomInput
                            type='select'
                            label="Purchase Order Type"
                            name="projectType"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="PO Type..."
                            options={[{ value: 'ADHOC', label: 'Adhoc' }, { value: 'FTEMS', label: 'FTE-Managed Services' }, { value: 'FTESA', label: 'FTE-Staff-Aug' }, { value: 'SERVICES', label: 'Services' }, { value: 'PRODUCT', label: 'Product' }, { value: 'TRACKER', label: 'Tracker' }]}
                            onFormError={this.onFormError}
                          />
                        </div>

                        <div className="col-3">
                          <CustomInput
                            type='select'
                            label="Type"
                            name="type"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Type..."
                            options={[{ value: 'BILLABLE', label: 'Billable' }, { value: 'BILLABLE_DA', label: 'Billable DA' }, { value: 'NON_BILLABLE', label: 'Non Billable' }]}
                            onFormError={this.onFormError}
                          />
                        </div>

                        <div className="col-6">
                          <CustomInput
                            type='select'
                            label="Account"
                            name="accountName"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Account..."
                            options={this.props.allPurchaseOrderTeamAccounts && commonUtils.getAccountOptions(this.props.allPurchaseOrderTeamAccounts)}
                            onFormError={this.onFormError}
                          />
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            type='select'
                            label="Team"
                            name="teamName"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Team..."
                            options={this.props.allPurchaseOrderTeamAccounts && this.state.purchaseOrderRequest && commonUtils.getTeamOptions(this.props.allPurchaseOrderTeamAccounts, this.state.purchaseOrderRequest.accountName)}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type='select'
                            label="Client"
                            name="clientNames"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Client..."
                            options={this.props.allPurchaseOrderTeamAccounts && this.state.purchaseOrderRequest && commonUtils.getClientsOptions(this.props.allPurchaseOrderTeamAccounts, this.state.purchaseOrderRequest.accountName, this.state.purchaseOrderRequest.teamName)}
                            onFormError={this.onFormError}
                            multi={true}
                          />
                        </div>

                      </div>
                      <div className="row">

                        <div className="col-12">
                          {commonUtils.getAppConfigValue(this.props.createConfig, "enableCategorySelection") && !(commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineSelection") || commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineEstimate")) &&
                            <CustomInput
                              type='select'
                              createConfig={this.props.createConfig}
                              label="Purchase Order Category"
                              name="category"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.purchaseOrderRequest}
                              onChange={this.onChange}
                              placeholder="Category..."
                              isAppConfig={true}
                              onFormError={this.onFormError}
                            />
                          }
                          {commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineSelection") && !commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineEstimate") &&
                            <CustomInput
                              type='select'
                              label="Service Line"
                              name="category"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.purchaseOrderRequest}
                              onChange={this.onChange}
                              placeholder="Category..."
                              options={this.props.createConfig && workItemUtils.getServiceLines(this.props.createConfig)}
                              onFormError={this.onFormError}
                            />
                          }
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            type='select'
                            label="Service Category"
                            name="serviceCategory"
                            createConfig={this.props.createConfig}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Service Category..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type='select'
                            label="Survey Type"
                            name="surveyType"
                            createConfig={this.props.createConfig}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Survey Type..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                          />
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-3">
                          <CustomInput
                            type='text'
                            label="Phone Number"
                            name="phoneNumber"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Phone Number..."
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-3">
                          <CustomInput
                            type='select'
                            label="Time Zone"
                            name="timezone"
                            createConfig={this.props.createConfig}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Time Zone..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                          />
                        </div>

                        <div className="col-3">
                          <CustomInput
                            type='select'
                            label="Currency"
                            name="currency"
                            createConfig={this.props.createConfig}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Currency..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-3">
                          <CustomInput
                            type='text'
                            label="Loi"
                            name="loi"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Loi..."
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-3">
                          <CustomInput
                            type='select'
                            label="Sample"
                            name="sample"
                            createConfig={this.props.createConfig}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Sample..."
                            isAppConfig={true}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-3">
                          <CustomInput
                            type='text'
                            label="Target Audience"
                            name="targetAudience"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Target Audience..."
                            onFormError={this.onFormError}
                          />
                        </div>

                        <div className="col-3">
                          <CustomInput
                            type='text'
                            label="Sample Targeting"
                            name="sampleTargeting"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Sample Targeting..."
                            onFormError={this.onFormError}
                          />
                        </div>

                        <div className="col-3">
                          <CustomInput
                            type='text'
                            label="Estimated IR"
                            name="estimatedIR"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Estimated IR..."
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <UserTeamSelection
                          inputClassName={"col-12"}
                          teamClasssName={"col-6"}
                          name="includedUserTeamIds"
                          label="Contributing Teams"
                          userTeams={this.props.allUserTeams}
                          enableAllTeams={true}
                          fieldConfig={this.props.fieldConfig}
                          mappingObject={this.state.purchaseOrderRequest}
                          onChange={this.onChange}
                          onFormError={this.onFormError}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-lg-6">
                      <div className="row">
                        <div className="col-6">
                          <CustomInput inputtype='text'
                            label="Purchase Order Number"
                            name="purchaseOrderNumber"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Purchase Order Number..."
                            datatype='alpnumspace' />
                        </div>
                        <div className="col-6 ">
                          <CustomInput inputtype='text'
                            label="Bid Id"
                            name="bidId"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Bid Id..."
                            datatype='alpnumspace' />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <CustomInput
                            type='textarea'
                            label="Summary"
                            name="summary"
                            rows={4}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Summary..."
                            onFormError={this.onFormError}
                            datatype='alpnumspl'
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput inputtype='text'
                            label="Client Company Name"
                            name="clientCompanyName"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Client Company Name.."
                            datatype='alpnumspace' />
                        </div>
                        <div className="col-6">

                          <CustomInput inputtype='text'
                            label="Client Partner"
                            name="clientPartner"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Client Partner/Sales Person..."
                            datatype='alpnumspace' />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput inputtype='text'
                            label="Sales Force Id"
                            name="salesForceId"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Sales Force Id..."
                            datatype='alpnumspace' />

                        </div>

                        <div className="col-6">
                          <CustomInput
                            type='select'
                            label="Location"
                            name="location"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Location..."
                            options={[{ value: 'Offshore', label: 'Offshore' }, { value: 'Onshore', label: 'Onshore' }]}
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Project Owner User Team"
                            name="userTeamId"
                            isIdAsSelectValue={false}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Project Owner User Team..."
                            options={commonUtils.getUserTeamsFromUserItem() && commonUtils.convertObjectToSelectObject(commonUtils.getUserTeamsFromUserItem())}
                            //options={this.props.allTeams && commonUtils.convertObjectToSelectGroupObject(this.props.allTeams)}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type='select'
                            label="Purchase Order Owner"
                            name="owner"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Purchase Order Owner..."
                            options={this.props.allEmployees && commonUtils.convertObjectToSelectObject(this.props.allEmployees)}
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <CustomInput
                            type='select'
                            label="Complexity"
                            name="complexity"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Complexity..."
                            options={[{ value: 'BASIC', label: 'Basic' }, { value: 'MEDIUM', label: 'Medium' }, { value: 'COMPLEX', label: 'Complex' }]}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            type='select'
                            label="Priority"
                            name="priority"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.purchaseOrderRequest}
                            onChange={this.onChange}
                            placeholder="Priority..."
                            options={[{ value: 'URGENT', label: 'Urgent' }, { value: 'HIGH', label: 'High' }, { value: 'MEDIUM', label: 'Medium' }, { value: 'LOW', label: 'Low' }]}
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>
                      {!commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineEstimate") &&
                        <div className="row">
                          <div className="col-4">
                            <CustomInput inputtype='text'
                              label="Estimated Cost"
                              name="estCost"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.purchaseOrderRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Estimated Cost..."
                              datatype='num' />
                          </div>

                          <div className="col-4">
                            <CustomInput inputtype='text'
                              label="Estimated Hourly Rate"
                              name="estHourlyRate"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.purchaseOrderRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Estimated Hourly Rate..."
                              datatype='num' />
                          </div>
                          <div className="col-4 ">
                            <CustomInput inputtype='text'
                              label="Estimated Hours"
                              name="estHours"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.purchaseOrderRequest}
                              onChange={this.onChange}
                              onFormError={this.onFormError}
                              placeholder="Estimated Hours..."
                              datatype='num' />
                          </div>
                        </div>
                      }
                      {commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineEstimate") &&
                        <ServiceLineEstimate
                          name="serviceLineEstimate"
                          readOnly={this.props.readOnly}
                          config={{
                            estHourEditEnabled: this.props.permission.enableServiceLineEstimateHourEditable,
                            estHourViewEnabled: this.props.permission.enableServiceLineEstimateHourVisible,
                            estHourlyCostEditEnabled: this.props.permission.enableServiceLineEstimateCostEditable,
                            estHourlyCostViewEnabled: this.props.permission.enableServiceLineEstimateCostVisible
                          }}
                          onChange={this.onChange}
                          serviceLineEstimate={this.state.purchaseOrderRequest.serviceLineEstimate || {}}
                          selectedServiceLines={this.state.purchaseOrderRequest.serviceLines}
                          fieldConfig={this.props.fieldConfig}
                          mappingObject={this.state.purchaseOrderRequest}
                          serviceLineOptions={this.props.createConfig && workItemUtils.getServiceLines(this.props.createConfig)}
                          onFormError={this.onFormError}
                        />
                      }
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <SidebarFooter
              handleSubmit={this.handleSubmit}
              editMode={this.props.editMode}
              duplicateMode={this.props.duplicateMode}
              initializeRequest={this.initializeRequest}
              isProcessing={this.props.isProcessing}
              hasFormError={this.state.hasFormError}
              onResetFormError={this.onResetFormError}
            />
          </>
        }
      </>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.authentication.user,
  fieldConfig: state.purchaseOrders.fieldConfig,
  createConfig: state.purchaseOrders.createConfig,
  allPurchaseOrderTeamAccounts: state.accounts.allProjectTeamAccounts,
  employeeAppConfig: state.employees.createConfig,
  allUserTeams: state.appconfig.allTeams,
  allEmployees: state.employees.allEmployees,
  creating: state.purchaseOrders.creating,
  updating: state.purchaseOrders.updating,
});

const mapDispatchToProps = dispatch => ({

  initializeCreatePurchaseOrder: purchaseOrderActions.initializeCreatePurchaseOrder
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePurchaseOrderSidebar);

