import React, { Component } from "react";
import { connect } from "react-redux";
import CronJobSelectionMenu from "views/common/fragments/CronJobSelectionMenu";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { taskActions } from "_actions";
import { employeeActions } from "_actions";
import { scheduleActions } from "_actions";
import { scheduleConstants } from "_constants";
import { commonUtils } from "_helpers";
import { store } from "_helpers";
import { validationUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";

class RecurringSelectionWindow extends Component {
    constructor() {
        super();
        this.state = {
            showCroDropdownMenu: false,
            cronValue: "0 05 0 ? * MON-FRI *",
            cronString: "At 12:05 AM, Monday Through Friday",
            formErrors: {}
        }
    }
    componentDidMount() {

        this.setState({
            scheduleRequest: {
                "userTeamId": this.props.userTeamId,
                "scheduleType": this.props.scheduleType,
                "itemId": this.props.itemId,
                "cronValue": "0 05 0 ? * MON-FRI",
                "cronString": "At 12:05 AM, Monday Through Friday",
                "recurringInfo": {
                    "type": this.props.type
                }
            }
        })
        this.handleGetAllProjectScheulers();
    }
    onCreateAnotherEnabled = (value) => {
        this.setState({
            createAnotherEnabled: value
        })
    }

    toggleEditOption = (value) => {
        if (value === true) {
            if(this.props.type === 'PROJECT') {
                store.dispatch(this.props.getAllRecurrableTasks({ projectId: this.props.itemId }));
            }
            if(this.props.type === 'TASK') {
                store.dispatch(this.props.getAllEmployees());
            }
        }
        store.dispatch({ type: value ? scheduleConstants.SHOW_CREATE_SCHEDULES_WINDOW : scheduleConstants.CLOSE_CREATE_SCHEDULES_WINDOW });
    }

    onFormError = (fieldName, hasError) => {
        this.setState(prevState => ({
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: hasError
            }
        }))
    }

    onChange = (fieldName, value) => {
        this.setState(prevState => ({
            scheduleRequest: {
                ...prevState.scheduleRequest,
                [fieldName]: value
            }
        }))
    }

    onRecurringInfoChange = (fieldName, value) => {
        this.setState(prevState => ({
            scheduleRequest: {
                ...prevState.scheduleRequest,
                recurringInfo: {
                    ...prevState.scheduleRequest.recurringInfo,
                    [fieldName]: value
                }
            }
        }))
    }

    toggeleCronMenu = (isShow) => {
        this.setState(prevState => ({
            showCroDropdownMenu: isShow
        }))
    }

    onCronValueChange = (cronValue, cronString) => {
        this.setState(prevState => ({
            cronValue,
            cronString,
            scheduleRequest: {
                ...prevState.scheduleRequest,
                "cronString": cronString,
                "cronValue": cronValue.substr(0, cronValue.length - 1)
            }
        }))
        this.toggeleCronMenu(false);
    }
    handleGetAllProjectScheulers = () => {
        store.dispatch(this.props.getSchedules({ itemId: this.props.itemId, userTeamId: this.props.userTeamId, type: this.props.type, scheduleType: this.props.scheduleType }));
    }
    handleSubmit = () => {
        let isValid = validationUtils.isValidRequest(this.props.fieldConfig, this.state.scheduleRequest, this.state.formErrors);
        //console.log("Validating form  request, isValid - " + isValid);
        if (isValid) {
            store.dispatch(this.props.createScheule(this.state.scheduleRequest, this.props.scheduleType));
        }
    }

    handleEnable = (itemId, isEnabled) => {
        store.dispatch(this.props.enableSchedule({ itemId, isEnabled }));
    }
    handleRemove = (itemId) => {
        store.dispatch(this.props.removeSchedule({ itemId }));
    }

    render() {
        return (
            <>
                <span className="subHeading col-12 section-banner">
                    <button className="link pr-2" ><i className="fa fa-chevron-down"></i></button>
                    Recurring
                    {!this.props.editable && !this.props.readOnly &&
                        < button onClick={() => this.toggleEditOption(!this.state.editable)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                            Edit
                        </button>
                    }
                </span>
                {this.props.editable && this.state.scheduleRequest &&
                    <div className="col-12 py-1 border">
                        <div className="row">
                            <div className="col-3">
                                <CustomInput inputtype='text'
                                    label="Name"
                                    name="name"
                                    fieldConfig={this.props.fieldConfig}
                                    mappingObject={this.state.scheduleRequest}
                                    onChange={this.onChange}
                                    onFormError={this.onFormError}
                                    placeholder="Name..."
                                    datatype='alpnumspl' />
                            </div>
                            <div className="col-4">
                                <label className="form-label text-muted">Schedule<span className="form-required">*</span></label>
                                <i className="fa fa-recycle pr-1 colorbulue" onClick={() => this.toggeleCronMenu(!this.state.showCroDropdownMenu)} />
                                <CronJobSelectionMenu
                                    showDropDown={this.state.showCroDropdownMenu}
                                    closeDropDwonMenu={this.toggeleCronMenu}
                                    onChange={this.onCronValueChange}
                                    cronValue={this.state.cronValue}
                                />
                                <span className="fsize13 fweight600 menu-text-black mr-5" onClick={() => this.toggeleCronMenu(!this.state.showCroDropdownMenu)}>{this.state.cronString}</span>


                            </div>
                            {this.props.type === 'PROJECT' && this.state.scheduleRequest.recurringInfo &&
                                <div className="col-3">
                                    <CustomInput readOnly={this.props.readOnly}
                                        type='select'
                                        label="Delivierables To Be included"
                                        name="childItemsTobeRecurrent"
                                        fieldConfig={this.props.fieldConfig}
                                        mappingObject={this.state.scheduleRequest.recurringInfo}
                                        onChange={this.onRecurringInfoChange}
                                        placeholder="Deliverables..."
                                        onFormError={this.onFormError}
                                        options={this.props.taskItemsitems && commonUtils.convertObjectToSelectObject(this.props.taskItemsitems)}
                                        multi={true}
                                    />
                                </div>

                            }
                            {this.props.type === 'TASK' && this.state.scheduleRequest.recurringInfo &&
                                <div className="col-3">
                                    <CustomInput readOnly={this.props.readOnly}
                                        type='select'
                                        label="Assignees (8 Hrs Default)"
                                        name="childItemsTobeRecurrent"
                                        fieldConfig={this.props.fieldConfig}
                                        mappingObject={this.state.scheduleRequest.recurringInfo}
                                        onChange={this.onRecurringInfoChange}
                                        placeholder="Employees..."
                                        onFormError={this.onFormError}
                                        options={this.props.allEmployees && commonUtils.convertObjectToSelectObject(this.props.allEmployees)}
                                        multi={true}
                                    />
                                </div>
                            }
                            <div className="col-2">
                                <CustomInput readOnly={this.props.readOnly}
                                    type='dateselect'
                                    label="End date"
                                    name="endDate"
                                    fieldConfig={this.props.fieldConfig}
                                    mappingObject={this.state.scheduleRequest}
                                    onChange={this.onChange}
                                    time={false}
                                    onFormError={this.onFormError}
                                    minDate={new Date()}
                                    currentDate={new Date()}
                                />

                            </div>
                        </div>
                        <div className="row col-6">
                            <button onClick={() => this.handleSubmit()} className="plainbutton text-upper border fweight600 fsize13">
                                Add
                            </button>
                            <button onClick={() => this.toggleEditOption(false)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue text-capitalize font-weight-normal">
                                Cancel
                            </button>
                        </div>
                    </div>
                }
                {this.props.items && this.props.items.map(item =>
                    <RecurringSelectionWindowLineItem
                        item={item} key={item.id}
                        handleEnable={this.handleEnable}
                        handleRemove={this.handleRemove}
                        readOnly={this.props.readOnly}
                    />
                )}
                {this.props.items && this.props.items.length === 0 &&
                    <span className="text-muted fsize12">This item is not scheduled, Please edit and add new schedules</span>
                }
            </>
        )
    }
}

class RecurringSelectionWindowLineItem extends Component {

    render() {
        return (
            <>
                <div className="col-12 py-1">

                    <span className="d-flex justify-content-between align-items-center">
                        <span className="mt-1 mx-0 px-0">
                            {this.props.item.enabled &&
                                <i className="fa fa-recycle pr-1 iconblueshadowanimated" />
                            }
                            {!this.props.item.enabled &&
                                <i className="fa fa-recycle pr-1 text-muted" />
                            }

                            <span className="fsize13 fweight600 menu-text-black mr-1">{this.props.item.name}</span>
                            <i className="fa fa-angle-double-right pr-1"></i>
                            <span className="fsize13 menu-text-black mr-1 colorblue">{this.props.item.cronString}</span>
                            <span className="fsize13 menu-text-black mr-1">With <span className="fweight600 colorblue">{this.props.item.recurringInfo.childItemsTobeRecurrent && this.props.item.recurringInfo.childItemsTobeRecurrent.length || '0'}</span> {this.props.item.recurringInfo.type === 'PROJECT' ? "deliverables" : "Assignees"}</span>
                            {this.props.item.endDate &&
                                <span className="fsize13 menu-text-black mr-0">Ends on <span className="colorred">{dateUtils.convertDateFormat(this.props.item.endDate)}</span></span>
                            }
                        </span>
                        <span className="mt-1 ml-4">
                            {this.props.item.enabled &&
                                <>
                                    <span className="fsize12 menu-text-black mr-2">Next Run : <span className="fweight600 colorblue">{this.props.item.nextRun}</span></span>
                                    <span className="fsize12 menu-text-black mr-2 d-none">Last Run :{this.props.item.lastRun}</span>
                                </>
                            }
                            {!this.props.readOnly &&
                                <>
                                    <span>
                                        <label className=" custom-switch custom-switch-sm m-0">
                                            <input type="checkbox" onChange={() => { }}
                                                onClick={e => this.props.handleEnable(this.props.item.id, !this.props.item.enabled)}
                                                checked={this.props.item.enabled ? true : false}
                                                className="custom-switch-input" />
                                            <span className="custom-switch-indicator" />
                                        </label>
                                    </span>
                                    <button type="button"
                                        onClick={(e) => this.props.handleRemove(this.props.item.id)}
                                        className="link pl-2" title="Remove"><i className="fa fa-times colorred" /></button>
                                </>
                            }
                        </span>
                    </span>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    selectedTaskInfo: state.tasks.selectedTaskInfo,
    fieldConfig: state.schedules.fieldConfig,
    taskItemsitems: state.tasks.recurrableTaskItems,
    items: state.schedules.items,
    editable: state.schedules.showCreateModel,
    allEmployees: state.employees.allEmployees
});

const mapDispatchToProps = dispatch => ({
    createScheule: scheduleActions.createSchedule,
    getSchedules: scheduleActions.getSchedules,
    enableSchedule: scheduleActions.enableSchedule,
    removeSchedule: scheduleActions.removeSchedule,
    getAllRecurrableTasks: taskActions.getAllRecurrableTasks,
    getAllEmployees: employeeActions.getAllEmployees,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecurringSelectionWindow);

